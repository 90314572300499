<template>

</template>
<script>
export default {
  name: 'Loader',
  props: ['globalData'],
  computed: {

  }
}
</script>
<style scoped>

</style>