<template>
  <Loader/>
  <Head :initData="initData" :contents = "activeContent"></Head>
  <Header :initData="initData" :contents = "activeContent"></Header>
  <div class="main-container">
    <router-view :initData="initData" :contents = "activeContent"/>
  </div>
  <Footer :initData="initData" :contents = "activeContent"></Footer>
</template>

<script>
import { reactive} from 'vue'
import Loader from "@/components/Loader.vue";
import Head from "@/components/Head.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default{
  name: 'App',
  props: ['serverData'],
  components:{
    Loader, Head, Header, Footer
  },
  data(){
    const initData = reactive(this.serverData)
    console.log(this.serverData)
    initData.loader.status = false

    return{
      initData
    }
  },
  computed:{
    activeContent(){
      let userLanguage = this.initData.init.language
      return this.initData.contents[userLanguage]
    },
  }
}
</script>

<style>
.main-container{
  width: 100%;
}
</style>
