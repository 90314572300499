<template>
  <header>
    <nav class="navbar navbar-expand-lg" :class="this.$route.path==='/' ? 'main-page' : 'sub-page'">
      <div class="container">
        <router-link class=" navbar-brand" :to="{name:'Page_275_'+language}">
          <img src="@/assets/img/header-logo.png" alt="Loxon Java Challenge logó" />
        </router-link>
        <button class="navbar-toggler ms-auto collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarList" aria-controls="navbarList" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarList">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{content.header.menuItem1}}</a>
              <div class="dropdown-menu dropdown-menu-center">
                <router-link class="dropdown-item" :to="{name : 'Page_270_'+language}">
                  {{content.header.menuItem1_01}}
                </router-link>
                <router-link class="dropdown-item" :to="{name : 'Page_278_'+language}">
                  {{content.header.menuItem1_02}}
                </router-link>
                <router-link class="dropdown-item" :to="{name : 'Page_279_'+language}">
                  {{content.header.menuItem1_03}}
                </router-link>
                <router-link class="dropdown-item" :to="{name : 'Page_280_'+language}">
                  {{content.header.menuItem1_04}}
                </router-link>
                <router-link class="dropdown-item" :to="{name : 'Page_276_'+language}">
                  {{content.header.menuItem1_05}}
                </router-link>
                <router-link class="dropdown-item" :to="{name : 'Page_281_'+language}">
                  {{content.header.menuItem1_06}}
                </router-link>
                  <router-link class="dropdown-item" :to="{name : 'Page_284_'+language}">
                  {{content.header.menuItem1_08}}
                </router-link>
                <a href="https://www.loxon.eu/career/" target="_blank" rel="noreferrer" class="dropdown-item">{{content.header.menuItem1_07}}</a>
              </div>
            </li>
            <li class="navbar-item">
              <router-link :to="{name:'Page_274_'+language}" class="nav-link">{{ content.header.menuItem2 }}</router-link>
            </li>
            <li class="navbar-item">
              <router-link :to="{name:'Page_277_'+language}" class="nav-link">{{ content.header.menuItem3 }}</router-link>
            </li>
            <li class="navbar-item">
              <a href="https://myaccount.ecosim.hu/versenyek/loxon_java_challenge_2023" target="_blank" rel="noreferrer" class="nav-link">{{ content.header.menuItem4 }}</a>
            </li>
            <li class="navbar-item">
              <router-link :to="{name:'Page_272_'+language}" class="nav-link">{{ content.header.menuItem5 }}</router-link>
            </li>
            <li class="navbar-item">
              <a href="https://www.facebook.com/javachallenge" target="_blank" rel="noreferrer" class="nav-link">
                <img src="@/assets/img/facebook.png" alt="Facebook" />
              </a>
            </li>
            <li class="navbar-item">
              <a href="" target="_blank" rel="noreferrer" class="nav-link">
                <img src="@/assets/img/youtube.png" alt="Youtube"/>
              </a>
            </li>
            <li class="navbar-item">
              <a href="" target="_blank" rel="noreferrer" class="nav-link">
                <img src="@/assets/img/user.png" alt="User"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <section class="breadcrumb" v-if="this.$route.path!=='/'">
    <div class="container">
      <router-link class=" navbar-brand" :to="{name:'Page_275_'+language}">Főoldal</router-link>
      <img src="@/assets/img/breadcumb-arrow.png" class="separator" />
      {{title}}
    </div>
  </section>
</template>
<script>
import {Response} from '@/classes/response.js'

export default{
  name:'Header',
  props:['initData','contents'],
  data(){
    return{
      language : this.initData.init.language
    }
  },
  computed:{
    content(){
      return this.contents[275]
    },
    title(){
      let title = this.initData.init.head.title
      let array = title.split(' | ')
      return array[0]
    }
  },
  methods:{
    // changeLanguage(){
    //   this.initData.init.language = this.language
    //
    //   let formData = new FormData()
    //   formData.append('language', this.language)
    //
    //   this.initData.loader.status = true;
    //   this.$axios.post('/api/v1/user/language',formData)
    //       .then((response) => {
    //         let responseData =  new Response().handle(response)
    //         this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
    //         if(responseData.status===true) {
    //           this.initData.init.language = this.language
    //           let routeName = this.$route.name
    //           let newRouteName = routeName.substring(0,routeName.length-2)+this.language
    //           let newRouteData = this.initData.init.routes.filter(function (el) {return el.name===newRouteName})[0]
    //           this.$router.push({name: newRouteData.name})
    //         }
    //
    //       })
    //       .catch(error => {
    //         console.log(error.message)
    //         console.error('There was an error!', error)
    //       })
    //       .then(() => {
    //         this.initData.loader.status = false;
    //       });
    // }
  }

}
</script>

<style scoped>
.navbar.main-page{
  padding: 40px 0;
}
.navbar.sub-page{
  padding: 0 0;
}
.navbar.main-page .navbar-brand img{
  max-width: 164px;
}
.navbar.sub-page .navbar-brand img{
  max-width: 110px;
}
.navbar.main-page .navbar-brand{
  position: absolute;
  top: 20px;
}
.navbar.sub-page .navbar-brand{
  position: relative;
}
.navbar .nav-item,.navbar .navbar-item{
  text-align: right;
}
.nav-link{
  font-family: 'TitilliumWeb-Light', sans-serif;
  color: var(--black);
}
.nav-link img{
  max-width: 22px;
}
.breadcrumb{
  background: url("@/assets/img/breadcumb-bgr.png") center center no-repeat;
  padding: 24px 0;
  font-family: 'TitilliumWeb-Light', sans-serif;
  color: #fff;
}
.breadcrumb .separator{
  margin: 0 0.5rem;
  max-width: 24px;
}
@media (max-width: 1030px) {

}
@media (max-width: 768px){

}
@media screen and (max-width: 576px) {
  .container{
    padding: 0 1rem;
  }
}
</style>