<template>
  <footer>
    <div class="container partners">
      <div class="row">
        <div class="col-12">
<!--          <h5>{{content.footer.partnersTitle}}</h5>-->
        </div>
        <div class="col-sm">
          <a href="https://prohardver.hu" target="_blank" rel="noreferrer">
            <img src="@/assets/img/partners/prohardver.png" alt="Prohardver"/>
          </a>
        </div>
        <div class="col-sm">
          <a href="https://mobilarena.hu" target="_blank" rel="noreferrer">
            <img src="@/assets/img/partners/mobilarena.png" alt="Mobilarena"/>
          </a>
        </div>
        <div class="col-sm">
          <a href="https://itcafe.hu" target="_blank" rel="noreferrer">
            <img src="@/assets/img/partners/it-cafe.png" alt="IT café"/>
          </a>
        </div>
        <div class="col-sm">
          <a href="https://www.infha.hu" target="_blank" rel="noreferrer">
            <img src="@/assets/img/partners/infha.png" alt="Informatikus Hallgatók Alapítványa"/>
          </a>
        </div>
        <div class="col-sm">
          <a href="https://www.facebook.com/SchonherzVallalatiKapcsolatok/" target="_blank" rel="noreferrer">
            <img src="@/assets/img/partners/schonherz.png" alt="Schönherz Vállalati Kapcsolatok"/>
          </a>
        </div>
        <div class="col-sm">
          <a href="https://www.inf.u-szeged.hu" target="_blank" rel="noreferrer">
            <img src="@/assets/img/partners/szte_ttik.png" alt="SZTE TTIK Informatikai Intézet"/>
          </a>
        </div>
      </div>

    </div>
    <div class="bottom-menu d-flex">
      <ul class="list-inline mx-auto justify-content-center">
        <router-link :to="{name:'Page_272_'+language}">{{content.footer.menuItem1}}</router-link>
        <a href="https://www.loxon.eu/privacy-policy/" target="_blank" rel="noreferrer">{{content.footer.menuItem2}}</a>
      </ul>
    </div>
    <div class="footer-logo">
      <img src="@/assets/img/footer-logo.png" alt="Loxon Java Challenge logó"/>
      <p class="copyright">© {{new Date().getFullYear()}} Loxon Java Challenge</p>
    </div>
  </footer>
</template>

<script>

export default{
  name:'Footer',
  props:['initData','contents'],
  data(){
    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      return this.contents[275]
    }
  }
}
</script>

<style scoped>
.partners{
  padding: 4rem 0;
}
.bottom-menu{
  width: 100%;
  background-color: var(--loxon-orange);
  padding: 1.6rem 0;
}
.bottom-menu ul{
  margin-bottom: 0;
}
.bottom-menu a{
  margin-right: 1.5rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: #fff;
}
.bottom-menu a:last-child{
  margin-right: 0;
}
.footer-logo{
  padding: 4rem 0;
  text-align: center;
}
.footer-logo img{
  max-width: 235px;
  margin-bottom: 2rem;
}
.copyright{
  text-transform: uppercase;
}
@media (max-width: 1030px) {

}
@media (max-width: 768px){

}
@media screen and (max-width: 576px) {
  .partners .col-sm{
    text-align: center;
  }
  .footer-logo img{
    max-width: 30%;
  }
}
</style>